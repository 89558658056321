import React from "react"
import styled from "styled-components"
import typography from "../utils/typography"
import { textIn } from "../components/reveal"

const inter = typography.options.bodyFontFamily.join();

const Tile = styled.div`
  margin-bottom: 6rem;
  animation: ${textIn} 750ms ease-in-out forwards;
  animation-delay: 1.55s;
  opacity: 0;


  &:nth-child(even) {
    grid-column: 3 / 5;
  }
`

const DetailLabel = styled.h3`
  margin-bottom: 1.5rem;
  font-family: ${inter};
  font-size: .875rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`

const DetailTile = ({ label, children }) => (
  <Tile>
    <DetailLabel>
      {label}
    </DetailLabel>
    {children}
  </Tile>
)

export default DetailTile
