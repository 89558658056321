import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import typography from "../utils/typography"

import Layout, { DetailSection } from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import DetailTile from "../components/detailTile"
import Reveal from "../components/reveal"
import CheckGuestForm from "../components/forms/checkEmailForm"

const ogg = typography.options.headerFontFamily.join()

const Hero = styled.div`
  display: grid;
  max-width: 36rem;
  min-height: 100vh;
  grid-column: 2 / -1;
  align-content: center;
`

const Offset = styled.div`
  width: 15rem;
  grid-column: 2 / -1; /* start position from DetailSection */
  grid-template-columns: minmax(0, 3fr) 1fr minmax(0, 3fr);

  @media screen and (min-width: 38rem) {
    display: grid;
    width: auto;
  }

  @media screen and (min-width: 46rem) {
    grid-column: 3 / -1; /* start position from DetailSection */
    grid-template-columns: minmax(0, 2fr) 1fr minmax(0, 2fr);
  }

  @media screen and (min-width: 56rem) {
    grid-column: 4 / -1; /* start position from DetailSection */
    grid-template-columns: minmax(0, 2fr) 1fr minmax(0, 2fr);
  }
`

const StyledText = styled.span`
  font-family: ${ogg};
  font-size: 1.875rem;
  line-height: calc(42 / 36);

  @media screen and (min-width: 37.5rem) {
    font-size: 2.25rem;
  }
`

const Italics = styled.em`
  font-style: italic;
  font-family: Ogg-Italic;
`

const Info = styled.p`
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  font-family: Inter;
  font-size: 1rem;
`

const LeadText = styled.p`
  margin-bottom: 1.5rem;
  font-family: Inter;
  font-size: 1.25rem;
`

const BodyText = styled.p`
  margin-bottom: 1.5rem;
  font-family: Inter;
  font-size: 1.125rem;
`

export const animateLine = keyframes`
  0% {
    background-size: 0% .125rem;
    background-position: 0 100%;
  }
  25% {
    background-size: 100% .125rem;
  }
  50% {
    background-size: 100% .125rem;
    background-position: -100% 100%;
  }
  75%, 100% {
    background-size: 200% .125rem;
    background-position: -100% 100%;
  }
`

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 600;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0% .125rem;

  &:hover {
    background-size: 100% .125rem;
    animation: ${animateLine} 1s ease-in-out infinite forwards;
  }
`


const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Elysia and Sam’s wedding is canceled." />
      <DetailSection>
        <Hero>
          <Reveal delay="2.15s">
            <LeadText>Family and friends,</LeadText>
            <BodyText>We regret to inform you that our wedding is canceled. As COVID-19 cases in Georgia continue to rise with no end in sight, we simply can’t justify putting our loved ones at risk.</BodyText>
            <BodyText>You’ve likely already heard from us at this point so hopefully this isn’t news to you.</BodyText>
            <BodyText>At this time, we are <em>not</em> planning to reschedule our wedding celebration.</BodyText>
            <BodyText>We hope we’ll have a chance to celebrate with you someday, but until then, stay safe out there!</BodyText>
            <BodyText>— Elysia & Sam</BodyText>
          </Reveal>
        </Hero>
      </DetailSection>

      <DetailSection>
        <Offset>
          <DetailTile label="Event">
            <StyledText>
              stay home.
            </StyledText>
            <Info>We’re bummed too. We even built some lovely emails we never sent.</Info>
          </DetailTile>

          <DetailTile label="Our website">
            <Info>We canceled the wedding before we finished the details...</Info>
            <StyledLink to="/archive">View the archive ➝</StyledLink>
          </DetailTile>
        </Offset>
        <Offset>
          <DetailTile label="">
            <StyledText><span style={{ whiteSpace: 'nowrap' }}>#kwakanalia</span></StyledText>
          </DetailTile>
        </Offset>
      </DetailSection>
    </Layout>
  )
}

export default IndexPage
